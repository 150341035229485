html,
body,
:global(#subapp_root_hr) {
  height: 100%;
  background-color: #f5f6fa;
  font-family: 'Segoe UI';
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:global(body p),
:global(body h1),
:global(body h2),
:global(body h3),
:global(body h4),
:global(body h5),
:global(body h6) {
  margin-bottom: 0;
}

:global(.pb1) {
  padding-bottom: 8px;
}

:global(.pb2) {
  padding-bottom: 16px;
}

:global(.pb3) {
  padding-bottom: 24px;
}

:global(.tc) {
  text-align: center;
}

/* rewrite the ant design form label style */
:global(.ant-form-item-label label) {
  color: #525252;
  font-family: 'Segoe UI';
  font-weight: normal;
}

:global(.ant-form-item-label label:before) {
  content: '';
  /* remove the red asterisk （*）*/
}

:global(#nprogress .bar) {
  background: #0d8ce6;
  position: fixed;
  z-index: 1024;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 2px;
}

:global(#nprogress .peg) {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #0d8ce6, 0 0 5px #0d8ce6;
  opacity: 1;
  transform: rotate(3deg) translate(0px, -4px);
}

:global(#nprogress .spinner) {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

:global(#nprogress .spinner-icon) {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #0d8ce6;
  border-left-color: #0d8ce6;
  border-radius: 50%;
  animation: nprogress-spinner 400ms linear infinite;
}

:global(#nprogress .nprogress-custom-parent) {
  overflow: hidden;
  position: relative;

  #nprogress {
    .bar,
    .spinner {
      position: absolute;
    }
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

:global(.workstream-no-hpadding .ant-popover-inner-content) {
  /* over write the ant design popover style */
  /* original value is 8px 15px */
  padding: 8px 0px;
}

:global(.sortableHelper) {
  z-index: 10001;
}

:global(li.ant-menu-item.WS-menu-multipleline) {
  height: auto !important;
  line-height: 16px !important;
  min-height: 42px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  white-space: pre-wrap !important;
  display: flex;
  align-items: center;
}

:global(textarea) {
  resize: vertical;
  padding: 5px;
}

:global(.ant-table-fixed-left) {
  box-shadow: 2px 1px 12px rgba(0, 0, 0, 0.1);
}

:global(.ant-table-fixed-right) {
  box-shadow: -2px 1px 12px rgba(0, 0, 0, 0.1);
}

:global(.WS-bordered-table table) {
  border: 1px solid #e6e6e6;
  table-layout: fixed;
}

:global(.WS-bordered-table tr:last-child > td) {
  border: none;
}

:global(.WS-bordered-table tr:last-child > td) {
  border: none;
}

:global(.WS-bordered-table .ant-table-placeholder) {
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
}

:global(.WS-bordered-table thead th) {
  border-bottom: 1px solid #e6eaee;
  background-color: #f2f3f9;
}

:global(.WS-left-pad-column) {
  padding-left: 20px !important;
}

:global(.WS-MCQOption-input input[type='text']) {
  padding-right: 100px !important;
}

:global(.WS-no-outline:focus) {
  box-shadow: none;
  outline: none;
}

:global(.WS-offer-template-textarea) {
  min-height: 98px;
}

/* offer template editor */
:global(.WS-offer-template-editor) {
  padding: 10px;
}

:global(.WS-offer-template-editor:focus) {
  box-shadow: none;
  outline: none;
}

:global(.WS-offer-template-editor .token-span) {
  background-color: #eff1fc;
  color: black;
  font-weight: 600;
  border-radius: 5px;
}

:global(.WS-offer-letter-preview p) {
  line-height: 24px;
  font-size: 14px;
  margin: 24px 0;
}

:global(.WS-merge-tag-editable) {
  font-size: 13px;
  font-family: 'Segoe UI', sans-serif;
  color: #525252;
  padding: 16px;
  margin: 0;
  word-break: break-word;
  white-space: normal;
}

:global(.WS-merge-tag-editable .WS-merge-tag) {
  font-family: 'Segoe UI', sans-serif;
  border-radius: 2px;
  padding: 2px;
  margin: 0 1px;
  color: #525252;
  background-color: #eff1fc;
}

:global(.WS-merge-tag-editable .WS-merge-tag-invalid) {
  font-family: 'Segoe UI', sans-serif;
  border-radius: 2px;
  padding: 2px;
  margin: 0 1px;
  color: #f84848;
  background-color: #ffe3e3;
}

:global(.WS-checkbox-large .ant-checkbox-inner) {
  width: 20px;
  height: 20px;
}

:global(.WS-checkbox-large .ant-checkbox-inner:after) {
  left: 6px;
  top: 2px;
  width: 7.5px;
  height: 12px;
}

:global(.WS-jobboard-tooltip) {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

:global(.WS-jobboard-tooltip-wrapper:hover .WS-jobboard-tooltip) {
  display: block;
  opacity: 1;
}

:global(.WS-AnalyticsFilter__Select .ant-select-selection__rendered) {
  display: none;
}

:global(.WS-AnalyticsFilter__Select .ant-select-selection--multiple) {
  cursor: pointer;
}

:global(.WS-AnalyticsFilter__Select .ant-select-search__field__placeholder) {
  display: block !important;
  color: #525252;
  font-weight: 600;
  left: 20%;
  cursor: pointer;
}

:global(.WS-AnalyticsFilter__Select-Popup .ant-select-tree li ul) {
  padding: 0;
}

:global(.WS-Analytics-Chart-Title) {
  font-weight: 600;
  cursor: auto;
  color: #525252 !important;
}

:global(g.recharts-cartesian-axis.recharts-yAxis.yAxis text) {
  text-anchor: middle;
}

:global(.WS-sortable-table-column) {
  cursor: pointer;
}

:global(.intl-tel-input) {
  width: 100%;
}

:global(.WS-table .ant-table-thead > tr > th) {
  background: none;
  border-bottom: 1px solid #e9e9e9;
}

:global(.WS-components-table .ant-table-expanded-row) {
  background: #ffffff;
}

:global(.WS-components-table .ant-table-expanded-row > td:last-child) {
  padding: 0 0 0 0;
}

:global(.WS-components-table .ant-table-expanded-row .ant-table-row:last-child) {
  border: none;
}

:global(.WS-components-table .ant-table-thead > tr > th) {
  background: none;
  border-bottom: 1px solid #e9e9e9;
}

:global(.WS-static-row-height) {
  vertical-align: top;
}

:global(.WS-hide-table-expand .ant-table-row-expand-icon) {
  display: none;
}

:global(.WS-components-table-row .ant-table-row-expand-icon-cell) {
  position: relative;
}

:global(.WS-components-table-row .ant-table-row-expand-icon) {
  border: 0;
  background-color: transparent;
}

:global(.WS-components-table .ant-table-small) {
  border: none;
  border-radius: 0;
  /*width: 488px*/
}

:global(.WS-components-table .ant-table-small .ant-table-body > table) {
  border: none;
  padding: 0;
}

:global(.WS-components-table .ant-table-small .ant-table-tbody > tr > td) {
  padding: 7px 8px;
}

:global(.WS-components-table .ant-table-expanded-row:hover > td) {
  background: #ffffff;
}

:global(.WS-components-table-row .ant-table-row-collapsed:after) {
  content: url('./assets/down-arrow.svg');
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;

  /* right: 16px; */
  top: 0;
  display: inline-block;
  transform: rotate(0deg);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  zoom: 1;
}

:global(.WS-components-table-row .ant-table-row-expanded:after) {
  content: url('./assets/down-arrow.svg');
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;

  /* right: 16px; */
  top: 0;
  display: inline-block;
  transform: rotate(180deg) translateY(-2px);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  zoom: 1;
}

:global(.WS-notification-slide-panel .slide-pane__header) {
  display: none;
}

:global(.WS-notification-slide-panel .slide-pane__content) {
  padding: 0px;
  background-color: #f5f6fa;
}

:global(.ReactModal__Overlay) {
  z-index: 1000;
}

:global(.slide-pane__overlay) {
  z-index: 1000;
}

:global(.slide-pane__overlay.ReactModal__Overlay--after-open.applicants-filter-slide-panel) {
  background-color: transparent !important;
  z-index: 999;
}

:global(.WS-jobboard-note) {
  background: #fffaeb;
  border: 1px solid #ffe9a8;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  position: relative;
}

:global(.WS-jobboard-note:before) {
  /*content: '';*/
  /*display: block;*/
  /*position: absolute;*/
  /*right: 25px;*/
  /*bottom: 100%;*/
  /*width: 0;*/
  /*height: 0;*/
  /*border: 10px solid transparent;*/
  /*border-bottom-color: #FFE9A8;*/
}

:global(.WS-jobboard-note:after) {
  /*content: '';*/
  /*display: block;*/
  /*position: absolute;*/
  /*right: 26px;*/
  /*bottom: 100%;*/
  /*width: 0;*/
  /*height: 0;*/
  /*border: 9px solid transparent;*/
  /*border-bottom-color: #FFFAEB;*/
}

/* Rewrite ant design button to make it borderless */
:global(.WS-text-button) {
  border: none;
  background: transparent !important;
}

:global(.WS-text-button:hover, .WS-text-button:active, .WS-text-button:focus, .WS-text-button:visited, ) {
  border: none;
  background: transparent !important;
}

/* sidebar position/department edit button */
:global(.WS-sidebar-edit-button-container .WS-sidebar-edit-button) {
  display: flex;
  margin-left: 2px;
  visibility: hidden;
}

:global(.WS-sidebar-edit-button-container:hover .WS-sidebar-edit-button) {
  visibility: visible;
}

/*  */
.mb0 {
  margin-bottom: 0;
}

/* Change style of Collapse */
:global(.custom-collapse i.arrow) {
  right: 0px;
  left: auto !important;
}

:global(.custom-collapse .ant-collapse-header) {
  padding-left: 0px !important;
  font-size: 14px !important;
  font-weight: 600;
}

:global(.custom-collapse .ant-collapse-content) {
  padding-left: 0px !important;
}

/* util class name to define an element that appears when hover on it's container */
:global(.WS-hover-container .WS-hover-content) {
  visibility: hidden;
}

:global(.WS-hover-container:hover .WS-hover-content) {
  visibility: visible;
}

/* for Radio.Group in application form editing  */
:global(.WS-radio-group-editing .ant-radio-group) {
  display: flex;
  flex-direction: column;
}

:global(.WS-radio-group-editing .ant-radio-group .ant-radio-wrapper) {
  display: flex;
  align-items: center;
}

:global(.WS-checkbox-group-editing .ant-checkbox-group) {
  display: flex;
  flex-direction: column;
}

:global(.WS-checkbox-group-editing .ant-checkbox-group .ant-checkbox-wrapper) {
  display: flex;
  align-items: center;
}

/* fix multiple line label text line-heigh too large issue */
:global(.WS-checkbox-group-editing .ant-checkbox-group .ant-checkbox-wrapper span) {
  line-height: 18px;
}

:global(.WS-checkbox-group-editing .ant-checkbox-group .ant-checkbox-wrapper:not(:last-child)) {
  margin-bottom: 13px;
}

:global(.WS-antd-menu-no-right-border.ant-menu) {
  border-right-color: transparent;
}

/* re-write Steps.step current step text color */
:global(.ant-steps .ant-steps-item.ant-steps-status-process .ant-steps-title) {
  color: #0d8ce6;
}

/* remove antd input :focus shadow */
:global(.WS-input-no-focus-shadow.ant-input:focus) {
  box-shadow: none;
}

:global(.custom-checkbox-group .ant-checkbox-group-item) {
  display: block;
}

/************ Customize the react-big-calendar ************/
:global(.rbc-today) {
  background-color: #f5f6fa !important;
}

/* Hide the all day row */
:global(.rbc-allday-cell) {
  display: none;
}

/* Remove border around the whole calendar */
:global(.rbc-time-view) {
  border: none !important;
}

/* Removes bottom border and increase padding of left-most time column */
:global(.rbc-time-gutter .rbc-timeslot-group) {
  padding: 0 10px 0 24px;
  border-bottom: none;
}

/* Vertically center the time display on left-most column */
:global(.rbc-time-gutter .rbc-timeslot-group .rbc-time-slot) {
  align-items: center;
  display: flex;
}

/* Removes right border of right-most header column */
:global(.rbc-time-header.rbc-overflowing) {
  border-right: none !important;
}

/* Removes 2px top border between header and content
   Always show scroll bar
*/
:global(.rbc-time-content) {
  border-top: none !important;
  /* overflow-y: scroll !important; */
  overflow-x: hidden;
}

/* Change the border color to a lighter gray */
:global(.rbc-header) {
  border-bottom-color: #e6eaee !important;
}

:global(.rbc-time-header-content) {
  border-left-color: #e6eaee !important;
}

:global(.rbc-header + .rbc-header) {
  border-left-color: #e6eaee !important;
}

:global(.rbc-time-content > * + * > *) {
  border-left-color: #e6eaee !important;
}

:global(.rbc-timeslot-group) {
  border-bottom-color: #e6eaee !important;
}

/* Disable hover on the parent div of events to allow hover of empty slots
  and make it full width
*/
:global(.rbc-day-slot .rbc-events-container) {
  pointer-events: none;
  right: 0 !important;
}

/* Enable hover on the event itself */
:global(.rbc-day-slot .rbc-event) {
  pointer-events: auto;
  width: auto !important;
}

/* Add text to empty slot on hover */
:global(.rbc-day-slot .rbc-time-slot) {
  display: flex;
}

/* Change background color of slot on hover */
:global(.edit-slot:hover) {
  background-color: #ecf6fd;
}

:global(.edit-slot:hover):after {
  content: 'Choose slot';
  color: rgb(13, 140, 230);
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

/* Hide time label for selected pending events */
:global(.custom-pending-event .rbc-event-label) {
  display: none;
}

/* Style the check in selected event */
:global(.custom-pending-event .rbc-event-content) {
  /* font-size: 28px; */
  /* font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center; */
}

:global(.custom-pending-event .rbc-event-content span) {
  display: flex;
}

:global(.rbc-day-slot .rbc-time-slot:not(.edit-slot)) {
  border-top: none !important;
}

/* Hide time label for Google events */
:global(.custom-calendar-event .rbc-event-label) {
  display: none;
}

:global(.custom-event-title) {
  display: block;
  white-space: nowrap;
  margin: -4px -6px 3px -6px;
  padding: 4px 6px;
  font-size: 80%;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

:global(.custom-google-event-title) {
  background-color: #2a94b6;
}

:global(.custom-others-event-title) {
  background-color: #e3703a;
}

:global(.custom-calendar-event .custom-google-event-title.past:not(.selected)) {
  background-color: #cfcfd4;
}

:global(.custom-calendar-event .custom-others-event-title.past:not(.selected)) {
  background-color: #cfcfd4;
}

:global(.custom-calendar-event .custom-others-event-title.none:not(.selected)) {
  background-color: #ffe4d8;
}

:global(.custom-calendar-event .custom-google-event-title.selected) {
  background-color: #217a97;
}

:global(.custom-calendar-event .custom-others-event-title.selected) {
  background-color: #cc6331;
}

:global(.custom-calendar-event-label),
:global(.custom-google-event-content) {
  display: block;
  white-space: nowrap;
  -ms-flex: none;
  -webkit-box-flex: 0;
  flex: none;
  padding-right: 5px;
  width: auto;
}

:global(.custom-calendar-event-label) {
  font-size: 10px;
  padding-bottom: 3px;
}

:global(.custom-google-event-content) {
  font-size: 11px;
  font-weight: 600;
}

/* Increase size of cells to 57px */
:global(.rbc-timeslot-group) {
  min-height: 101px !important;
}

/* More space between time and icons */
:global(.rbc-day-slot .rbc-event-label) {
  font-size: 10px;
  margin-bottom: 2px;
}

/* Remove box-shadow for 1st event on slot */
:global(.rbc-event[style*='left: 0%']) {
  box-shadow: none !important;
}

/* Modal Calendar */
:global(.WS-CalendarModal .ant-modal-content) {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
}

/************ (End) Customize the react-big-calendar ************/
:global(.delete-slot-modal .anticon) {
  color: #525252 !important;
}

:global(.update-slot-modal .anticon) {
  color: #ffbf00 !important;
}

:global(.schedule-confirm-modal .anticon) {
  color: #ffbf00 !important;
}

:global(.bulk-edit-slot-modal .ant-modal-footer) {
  border-top: none;
  padding: 0px 30px 20px 10px;
}

:global(.right-panel-time-fields .ant-form-explain) {
  position: absolute;
  line-height: 1;
}

:global(.ant-collapse > .ant-collapse-item.schedule-advanced-settings > .ant-collapse-header) {
  color: #525252;
}

/* prevent Rich Text Editor's line-height been re-write when nested in ant form item */
:global(.public-DraftEditor-content) {
  line-height: 1.5;
}

:global(.WS-positions-sidebar-search input) {
  border: none;
  padding-left: 28px !important;
}

/* modify antd primary button style a little bit */
:global(.ant-btn-primary) {
  background: #0d8ce6;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
}

:global(.ant-btn-primary:not(.ant-btn-circle)) {
  border-radius: 4px;
}

/* Manually display stage id errors on top */
:global(.scheduling-stage-ids .has-error .ant-form-explain) {
  display: none;
}

/* Scheduling Settings */
:global(.scheduleSettingsInputAddon + .ant-input-group-addon) {
  width: 80px;
  background-color: #ebf1f9;
}

:global(.scheduleSettingsInputAddon) {
  border-right: none;
}

/* Add note input box */
:global(.addNoteInputBox) {
  transition: all 0.3s;
  border: 1px solid #e6eaee;
  border-radius: 3px;
}

:global(.addNoteInputBox.addNoteInputBoxFocused) {
  border: 1px solid #0d8ce6;
  box-shadow: 0 0 6px 0 #c5c5c5;
}

:global(.custom-question-column) {
  max-width: 200px;
  min-width: 100px;
}

:global(.custom-answer-column) {
  display: inline-block;
  max-width: 200px;
  min-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

:global(.WS-filter-slide-panel-overlay) {
  left: 0 !important;
  background-color: transparent !important;
}

:global(.ant-select-tree li ul) {
  padding-left: 6px;
}

:global(.WS-flex-spinner),
:global(.WS-flex-spinner .ant-spin-container) {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
}

:global(.scheduleTimeColumn span) {
  display: flex;
  align-items: center;
}

:global(.WS-time-filter-hidden) {
  width: 0;
  height: 0;
  overflow: hidden;
}

:global(.WS-date-filter-modal) {
  position: fixed;
  z-index: 1000;
}

:global(.WS-scheduling-filter-icon) {
  width: 41px;
  height: 27px;
  border-radius: 13.6px;
  background-color: #e5e9f5;
}

:global(.WS-scheduling-filter-icon:hover) {
  background-color: #dadeeb;
  cursor: pointer;
}

/* Adjust table select column width from 62 to 48px */
:global(.ant-table-thead > tr > th.ant-table-selection-column),
:global(.ant-table-tbody > tr > td.ant-table-selection-column) {
  min-width: 48px !important;
  width: 48px !important;
}

/* some utility class name */
:global(.unselectable) {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

:global(.h34px) {
  height: 34px;
}

:global(.w220px) {
  width: 220px;
}

/* Removes the default border on the left and right of fixed tables */
:global(.ant-table-fixed-left .ant-table-fixed) {
  border-right: none !important;
}

:global(.ant-table-fixed-right .ant-table-fixed) {
  border-left: none !important;
}

/* antd modal */
:global(.WS-modal-body-share-position .ant-modal-title) {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
  overflow: hidden;
}

:global(.WS-modal-body-share-position .ant-modal-content) {
  background-color: #f5f6fa;
}

:global(.WS-modal-body-share-position .ant-modal-body) {
  padding: 0;
}

:global(.WS-modal-body-no-padding .ant-modal-body) {
  padding: 0;
}

:global(.WS-modal-body-no-padding .ant-modal-body),
:global(.ant-spin-nested-loading) {
  height: 100%;
}

:global(.WS-modal-body-no-padding .ant-modal-body),
:global(.ant-spin-container) {
  height: 100%;
}

/* antd Menu (use the menu item selected style when mouse hovering, Phil ask for it WS-790)  */
:global(.ant-menu-item-active),
:global(.ant-menu-submenu-active) {
  background-color: #ecf6fd;
}

/* change default padding to margin, to prevent underline highlight from showing in padded area */
:global(.ant-menu-horizontal > .ant-menu-item) {
  margin: -1px 0 0 32px;
  padding: 0;
}

/* antd Menu (mode="horizontal") remove blue bottom border, Phil ask for it WS-732*/
:global(.ant-menu-horizontal > .ant-menu-item:hover) {
  border-bottom: 2px solid transparent;
}

:global(.ant-menu-horizontal > .ant-menu-item-active) {
  border-bottom: 2px solid transparent;
}

:global(.ant-menu-horizontal > .ant-menu-item-selected) {
  border-bottom: 2px solid #108ee9 !important;
  /* !important prevent a selected menu item border disappear while hovering */
}

/* antd Switch: change the font-size to smaller one and move text a little bit up to vertical centering */
:global(.ant-switch-inner) {
  font-size: 10px;
  margin-top: -1px;
}

/* change the default antd menu color change time: from 0.3s to 0.1s */
:global(.ant-menu-item) {
  /* original */
  /* transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) */

  /* changed */
  transition: color 0s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0s cubic-bezier(0.645, 0.045, 0.355, 1),
    background 0s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* Making more room for the prefix component for the input componet */
:global(.WS-ant-input-padding-50 input) {
  padding-left: 50px !important;
}

/* animate bulk edit hint panel */
:global(.disappear-left-360) {
  width: 360px;
}

:global(.disappear-left-360-exit) {
  opacity: 1;
  width: 360px;
}

:global(.disappear-left-360-exit-active) {
  opacity: 0.01;
  width: 0px;
  transition: all 700ms ease;
}

/* search box in schedule page */
:global(.WS-schedule-stage-search .ant-input) {
  border-radius: 17px;
  border-color: #e6eaf2;
}

/* Fixed table header */
:global(.shadowTable tbody) {
  visibility: collapse;
}

:global(.shadowTable .ant-pagination.ant-table-pagination) {
  display: none;
}

:global(.slide-pane__overlay.ReactModal__Overlay--after-open) {
  background-color: rgba(1, 33, 56, 0.5) !important;
}

:global(.ant-modal-mask) {
  background-color: rgba(1, 33, 56, 0.5) !important;
}

:global(.WS-schedule-stage-search.ant-input-affix-wrapper .ant-input:not(:first-child)) {
  padding-left: 32px;
}

:global(.WS-schedule-stage-tree li span.ant-tree-switcher) {
  color: #bfbfbf;
}

:global(.WS-schedule-stage-tree li span.ant-tree-switcher:after) {
  font-size: 16px !important;
}

:global(.WS-schedule-stage-tree.ant-tree li .ant-tree-node-content-wrapper) {
  width: 84%;
}

:global(.WS-schedule-stage-tree.ant-tree li span.ant-tree-checkbox) {
  margin-top: 3px;
}

:global(.WS-schedule-stage-tree.ant-tree li.WS-positions-stage-node span.ant-tree-checkbox) {
  margin-top: 5px;
  vertical-align: top;
}

/* Show children of matched locations */
:global(.WS-schedule-stage-tree.ant-tree
    li.WS-positions-location-node.WS-show-children
    li.WS-positions-stage-node.WS-display-none) {
  display: block;
}

/* Adjust padding of locations */
:global(.WS-schedule-stage-tree.ant-tree .WS-positions-location-node) {
  padding-top: 2px;
}

/* Adjust padding of 1st stage of each location */
:global(.WS-schedule-stage-tree.ant-tree .WS-positions-stage-node:first-child) {
  padding-top: 8px;
}

/* Adjust padding of subsequent stages of each location */
:global(.WS-schedule-stage-tree.ant-tree .WS-positions-stage-node) {
  padding-top: 0;
}

/* end - search box in schedule page */

:global(.WS-display-none) {
  display: none;
}

/* rewrite disabled input background base on Phil's design */
:global(.ant-input-disabled) {
  background-color: #f6f8f9;
}

:global(.ant-select-disabled .ant-select-selection) {
  background-color: #f6f8f9;
}

/* manage user modal input */
:global(.ant-form-item-label label) {
  letter-spacing: 0;
}

:global(.ant-form-item-label label.ant-form-item-required:before) {
  display: none;
}

/* end - manage user modal input */

:global(.WS-ApplicantsFilter__Select .ant-select-selection--multiple) {
  min-height: 33px;
  cursor: pointer;
}

:global(.WS-ApplicantsFilter__Select .ant-select-search__field__placeholder) {
  font-size: 14px;
  color: #525252;
  top: 16px;
  left: 10%;
  height: 19px;
}

/* Rich editor*/
:global(.RichTextEditor__editor___1QqIU .RichTextEditor__paragraph___3NTf9) {
  margin: 0px !important;
}

/* ms login button */
:global(button.btn-microsoft-login) {
  border: none;
  background: none;
  cursor: pointer;
}

/* Bulk edit confirmation page actions table */
:global(.WS-BE-actions-table th) {
  padding-left: 27px !important;
}

:global(.WS-BE-actions-table td) {
  padding: 0 !important;
  vertical-align: top !important;
}

:global(.WS-full-height-spin) {
  height: 100%;
}

/* Animate close circle */
:global(.anticon-close-circle) {
  cursor: pointer;
  color: #ccc;
  transition: color 0.3s;
  font-size: 12px;
}

:global(.anticon-close-circle:hover) {
  color: #999;
}

:global(.ant4-notification-notice-close-icon svg) {
  display: none;
}

:global(.design-system-notification .ant4-notification-notice-with-icon) {
  display: flex;
  align-items: center;
}

:global(.design-system-notification .ant4-notification-notice-with-icon .ant4-notification-notice-message) {
  margin-bottom: 0;
  font-size: 14px;
  padding-right: 0;
  color: #27272a;
}

:global(.design-system-notification .ant4-notification-notice-close .ant4-notification-notice-close-icon::before) {
  color: #27272a;
  font-size: 12px;
}

:global(.design-system-notification .ant4-notification-notice-close) {
  top: 21px;
}

/* Customize position template search input margins */
:global(.WS-position-template-search .ant-input-prefix) {
  left: 12px;
}

:global(.WS-position-template-search .ant-input:not(:first-child)) {
  padding-left: 37px;
}

/* Add right margin only for JD inputs on the left */
:global(.WS-JD-input:nth-child(odd)) {
  /* margin-right: 40px; */
}

:global(.WS-full-height-spin .ant-spin-container) {
  height: 100%;
}

:global(.WS-auto-width-spin .ant-spin-container) {
  width: auto !important;
}

/* Change default disabled style */
:global(.ant-btn-primary.WS-button.disabled),
:global(.ant-btn-primary.WS-button[disabled]) {
  opacity: 0.3;
  background: #0d8ce6;
  border-color: #0d8ce6;
}

:global(.applicantsPopover .ant-popover-inner-content) {
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
}

:global(.applicantsPopover.ant-popover-placement-bottom
    > .ant-popover-content
    > .ant-popover-arrow:after, .applicantsPopover.ant-popover-placement-bottomLeft
    > .ant-popover-content
    > .ant-popover-arrow:after, .applicantsPopover.ant-popover-placement-bottomRight
    > .ant-popover-content
    > .ant-popover-arrow:after) {
  border-bottom-color: rgba(0, 0, 0, 0.75);
}

:global(div.arrow) {
  width: calc(100% + 32px);
  margin-left: -16px;
  height: 1px;
  background: #e6eaee;
  position: relative;
  margin-top: 15px;
}

:global(div.arrow:after) {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0 15px 15px;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -14px;
  left: 5%;
}

:global(div.arrow:before) {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0 15px 15px;
  border-color: #e6eaee transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -15px;
  left: 5%;
}

/* custom the Step style in offer stage UploadFile component */
:global(.WS-steps .ant-steps .ant-steps-item.ant-steps-status-finish .ant-steps-head-inner) {
  border-color: #45cf86;
  background-color: transparent;
}

:global(.WS-steps .ant-steps .ant-steps-item.ant-steps-status-process .ant-steps-head-inner) {
  border-color: #45cf86;
  background-color: #45cf86;
}

:global(.WS-steps .ant-steps .ant-steps-item.ant-steps-status-finish .ant-steps-head-inner > .ant-steps-icon) {
  color: #45cf86;
}

:global(.WS-steps .ant-steps .ant-steps-item.ant-steps-status-finish .ant-steps-tail > i:after) {
  background-color: #e6eaee;
  background: #e6eaee;
  transition: none;
  box-shadow: none;
  animation: none;
}

/* reset password input */
:global(.WS-reset-password-email input) {
  padding-left: 30px !important;
}

/* merge tag editor begin */
:global(.hide-toolbar) {
  display: none;
}

:global(.mte-root span) {
  font-family: 'Segoe UI' !important;
}

:global(.mte-subject .public-DraftStyleDefault-block) {
  overflow-x: auto;
  white-space: pre;
  padding: 5px 0px;
}

:global(.mte-subject .public-DraftEditor-content) {
  padding: 0px !important;
}

/* :global(.mte-body .DraftEditor-root) {
  padding: 15px 8px 0px;
} */

:global(.mte-body .public-DraftEditor-content) {
  min-height: 100px;
}

:global(.mte-body .mte-merge-tag) {
  display: inline-block;
}

/* :global(.mte-referral-body .DraftEditor-root) {
  padding: 0;
} */

:global(.mte-referral-body .public-DraftEditor-content) {
  min-height: 100px;
}

:global(.mte-referral-body .mte-merge-tag) {
  display: inline-block;
}

/* :global(.mte-custom-toolbar) {
  margin: 0px !important;
  padding: 0px 0px !important;
  background: #f2f3f9;
  border-bottom: 1px solid #e6eaee !important;
} */

:global(.mte-custom-toolbar > div) {
  margin-bottom: 0px !important;
  height: 30px;
}

:global(.mte-custom-toolbar button) {
  border-radius: 0px !important;
  background: transparent;
  border: 0px !important;
  padding-left: 5px;
  padding-right: 5px;
}

:global(.mte-custom-toolbar button > span) {
  background-size: 20px !important;
}

:global(.mte-custom-toolbar .custom-toolbar-btn[class*='isActive']) {
  background-color: #cccccc;
}

:global(.mte-custom-toolbar .custom-toolbar-btn span) {
  background-size: 24px !important;
}

:global(.mte-custom-toolbar .custom-toolbar-btn span) {
  background-size: 24px !important;
}

:global(.ant-collapse) {
  border: 0px;
  background-color: #fff;
}

:global(.ant-collapse > .ant-collapse-item) {
  border-bottom: 0px;
}

:global(:root .ant-collapse > .ant-collapse-item > .ant-collapse-header[aria-expanded='true'] .arrow) {
  font-size: 16px;
  color: #b9c2cb;
}

:global(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
  color: #b9c2cb;
  font-size: 14px;
}

:global(.ant-form-item-label label:after) {
  display: none;
}

:global(.triangle-down) {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 9px 0 9px;
  border-color: #e6eaee transparent transparent transparent;
  line-height: 0;
}

:global(.triangle-down:before) {
  content: '';
  position: absolute;
  top: -10px;
  left: -9px;
  border-top: 9px solid #f2f3f9;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
}

:global(.ant-switch-disabled) {
  opacity: 0.6;
  background: rgba(0, 0, 0, 0.25);
}

:global(.ant-switch-disabled:after) {
  background: #fff;
}

:global(.ant-switch-checked.ant-switch-disabled) {
  background: #108ee9;
}

/* merge tag editor end */

/* Change default switch style */
:global(.WS-switch.ant-switch) {
  min-width: 40px;
  background-color: #e3e3e3;
}

:global(.WS-switch.ant-switch-checked) {
  background-color: #dcecf6;
}

:global(.WS-switch.ant-switch-checked:after) {
  background-color: #0d8ce6;
}

/* Custom ghost button for EditPosition */
:global(.WS-ghost-published.ant-btn:hover),
:global(.WS-ghost-unpublished.ant-btn:hover),
:global(.WS-ghost-published.ant-btn:focus),
:global(.WS-ghost-unpublished.ant-btn:focus),
:global(.WS-ghost-published.ant-btn:active),
:global(.WS-ghost-unpublished.ant-btn:active) {
  color: #ffffff4a;
  border-color: #ffffff4a;
}

:global(.WS-left-open-submenu > .ant-dropdown-menu) {
  left: auto;
  right: 100%;
}

:global(.WS-no-padding-submenu-title .ant-dropdown-menu-submenu-title) {
  padding: 0 !important;
  position: relative;
}

:global(.WS-no-padding-submenu-title) {
  padding: 0 !important;
  position: relative;
}

:global(.WS-no-padding-submenu-title .ant-dropdown-menu-submenu-title:after) {
  top: 8px;
}

/* More obvious scroll table column in OfferStageModalContent.js */
:global(.ScrollbarsCustom-TrackX) {
  background: #f3f2f8 !important;
  width: 100% !important;
  left: 0 !important;
}

:global(.ScrollbarsCustom-ThumbX) {
  background: #148ee4 !important;
}

:global(.WS-scrollTable-right table) {
  border-left: none !important;
  border-radius: 0px 4px 0 0;
}

:global(.WS-scrollTable-right .ant-table) {
  border-radius: 0px 4px 0 0;
}

/* messages */
:global(.WS-message-header-show) {
  padding: 15px 16px;
  opacity: 1;
  height: auto;
}

:global(.WS-message-header-hide) {
  transition: height 0.1s ease-in-out;
  opacity: 0;
  height: 0;
  padding: 0 !important;
}

:global(li.ant-menu-item.WS-new-message) {
  padding-left: 16px !important;
  height: 56px;
  line-height: 56px;
}

:global(li.ant-menu-item.WS-new-message:after) {
  padding-left: 16px !important;
  height: 56px;
  line-height: 56px;
  border-right: 2px solid #108ee9;
}

:global(.WS-message-item) {
  position: relative;
}

:global(.WS-message-item.active:after) {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-right: 2px solid #108ee9;
}

/* Demo: Aug 6, 2019 Full Calendar Style Customization */
/* ------------------------------------------------------------------------- */
:global(.fc-unthemed td.fc-today) {
  background-color: #f5f6fa !important;
  border-left-color: #e6eaee;
}

:global(tbody tr:first-child .fc-axis.fc-time.fc-widget-content) {
  position: relative;
  top: -10px;
}

:global(tbody tr:not(:first-child) .fc-axis.fc-time.fc-widget-content) {
  position: relative;
  top: -18px;
}

:global(.fc-time-grid .fc-slats td) {
  height: 32px !important;
}

:global(.WS-fc-column-header) {
  /* margin: 8px 0 8px 16px; */
  cursor: pointer;
  text-align: left;
}

:global(.WS-fc-column-header--day) {
  font-size: 12px;
  color: #666;
  font-weight: normal;
}

:global(.WS-fc-column-header--date) {
  font-size: 50px;
  font-weight: 600;
  line-height: 1;
  color: #666;
}

:global(.fc-toolbar.fc-header-toolbar) {
  height: 0px;
  margin-bottom: 0px !important;
}

:global(.fc-time-grid .fc-slats .fc-minor td) {
  border-color: transparent !important;
}

:global(.fc-unthemed td.fc-widget-content) {
  border-color: #e6eaee !important;
}

:global(td.fc-widget-content) {
  border-left-color: transparent !important;
}

:global(.fc-unthemed .fc-axis.fc-time.fc-widget-content) {
  border-top-color: transparent !important;
}

:global(td.fc-head-container.fc-widget-header) {
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

:global(.WS-event .WS-event-content) {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  padding: 2px;
}

/* creating state event */

/* hide the loading modal comfirmation button when user click 'edit' after multiple checked events */
:global(.WS-no-footer-info-modal .ant-confirm-btns) {
  display: none;
}

:global(.ant-calendar-input-wrap) {
  display: none;
}

/* wizard */
:global(.wizard-step) {
  width: 100%;
  height: 5px;
  border-radius: 3px;
  background-color: #f1f1f1;
  display: inline-block;
  margin-right: 5px;
}

:global(.wizard-step.active) {
  background-color: #0d8ce6;
}

:global(.gray-line) {
  margin: 0 auto;
  width: 225px;
  height: 1px;
  border: solid 1px rgba(215, 215, 215, 0.52);
}

:global(.ant-modal-centered) {
  text-align: center;
}

:global(.ant-modal-centered::before) {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}

:global(.ant-modal-centered .ant-modal) {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

:global(.ant-confirm-body .ant-confirm-content) {
  word-break: break-word;
}

:global(.WS-table-no-wrap .ant-table-thead > tr > th) {
  word-break: initial;
  white-space: nowrap;
}

:global(.WS-table-no-wrap .ant-table-tbody > tr > td) {
  word-break: initial;
  white-space: nowrap;
}

:global(.WS-copy-box) {
  background: rgba(6, 6, 6, 0.292778);
  border-radius: 4px;
  height: 37px;
}

:global(.WS-copy-box ::selection) {
  background: none;
}

:global(.ant-btn.WS-transparent-button) {
  border: 1px solid #ffffff;
  background-color: transparent;
  height: 37px;
}

:global(.ant-btn.WS-transparent-button:hover),
:global(.ant-btn.WS-transparent-button:focus) {
  background-color: rgba(103, 103, 103, 0.37);
}

:global(body.WS-joyride, body.WS-joyride #subapp_root_hr) {
  background: none;
}

:global(body.WS-joyride .ant-modal-wrap) {
  z-index: 1001;
}

:global(body.WS-joyride .pac-container) {
  z-index: 1002;
}

:global(body.WS-joyride div[x-placement='bottom'] div[data-action='skip']) {
  top: -15px;
}

:global(body.WS-joyride div[x-placement='left'] div[data-action='skip']) {
  right: 15px;
}

:global(body.WS-joyride .react-joyride__overlay) {
  background-color: rgba(0, 0, 0, 0.76) !important;
}

:global(.WS-vertical-center-modal) {
  text-align: center;
  white-space: nowrap;
}

:global(.WS-vertical-center-modal:before) {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

:global(.WS-vertical-center-modal .ant-modal) {
  display: inline-block;
  vertical-align: middle;
  top: 0;
  text-align: left;
}

/* In our design, the ant form label is always 13px */
:global(.ant-form-item-label) {
  font-size: 13px;
}

:global(.WS-no-checkmark-dropdown .ant-select-dropdown-menu-item:hover::after) {
  display: none;
}

:global(.WS-disabled-select .ant-select-selection) {
  background-color: white;
  cursor: default;
}

:global(.slide-pane_from_left.ReactModal__Content--after-open) {
  transform: translateX(20%);
}

:global(.WS-position-manager-dropdown-item:hover) {
  font-weight: 600;
}

:global(.WS-brand-suffix-for-location) {
  margin-left: 8px !important;
}

:global(.improved-scheduling .fc-event) {
  /*  the calendar CSS load after index.css, so need to use important */
  background-color: transparent !important;
}

:global(.item-signer-prepare-hs-modal-enter) {
  opacity: 0;
}

:global(.item-signer-prepare-hs-modal-enter-active) {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

:global(.item-signer-prepare-hs-modal-exit) {
  height: 100px;
  opacity: 0;
}

:global(.item-signer-prepare-hs-modal-exit-active) {
  height: 0px;
  transition: height 300ms ease-out;
}

/* CSSTransition animation class `.section` */
:global(.brand-section-enter) {
  opacity: 0;
  transform: scale(0.9);
}

:global(.brand-section-enter-active) {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

:global(.brand-section-exit) {
  opacity: 1;
}

:global(.brand-section-exit-active) {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

/* PopOver Overwrites */

:global(.dropdown-option) {
  height: 44px;
  width: 200px;
  border-radius: 0px;
  padding: 12px 16px 12px 16px;
}

:global(.dropdown-option:hover) {
  background: #f9fafb;
  cursor: pointer;
}

:global(.drop-down-menu-wrapper .ant-popover-arrow) {
  display: none;
}

:global(.drop-down-menu-wrapper .ant-popover-inner) {
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
}

:global(.ant-select .ant-select-selection[role='combobox'] .ant-select-selection__rendered ul) {
  margin-bottom: 0;
}

:global(.ws-ant-menu-submenu-active) {
  background-color: #ecf6fd;
}

:global(.nfw-toast.ant4-notification-notice) {
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.12);
  padding: 20px;
}

:global(.info-nfw-toast .ant4-notification-notice-icon) {
  color: #3fabf8;
}

:global(.warning-nfw-toast .ant4-notification-notice-icon) {
  color: #ffc909;
}

:global(.success-nfw-toast .ant4-notification-notice-icon) {
  color: #75bd5e;
}

:global(.error-nfw-toast .ant4-notification-notice-icon) {
  color: #ff7152;
}

:global(.nfw-toast .ant4-notification-notice-close-x) {
  color: #27272a;
}

:global(.nfw-toast .ant4-notification-notice-description) {
  margin-right: 30px;
}

:global(.ws-tooltip .ant4-tooltip-inner) {
  border-radius: 8px;
  padding: 8px 10px;
}

:global(.ws-message .ant4-message-custom-content) {
  display: flex;
  align-items: center;
}
